/* .App {
  text-align: center;
} */
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
body {
  font-family: "Roboto";
  background-color: #f6fbff;
}
header{
  font-size: 30px !important;
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0b0c0c;
}
p,
li {
  color: #0b0c0c;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-logo {
  width: 358px;
}
.home_banner {
  width: 537px;
}
/* Components and sidebar */
.components_item {
  width: calc(100% - 14rem);
  height: auto;
  margin-left: 2rem;
  margin-top: 3rem;
}
.custom-shadow {
  border-radius: 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.input-shadow {
  border-radius: 7px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.navbar_shadow {
  box-shadow: 0px -8px 25px rgba(0, 0, 0, 0.25);
}
.active_class {
  width: 5rem;
  height: auto;
  background-color: red;
  color: yellow;
}
@media screen and (max-width: 768px) {
  .components_item {
    margin-left: 1rem;
    margin-top: 2rem;
  }
}
/* Components and sidebar */
/* Component Button */
.components_button2 {
  background: #f47738;
  box-shadow: inset 0px -2px 0px #0b0c0c;
}
/* Component Button */
